import React from "react";
import H4 from "../h4";
import LiWithArrow from "../li-with-arrow";
import PropTypes from "prop-types";
import useLink from "../../../hooks/use-link";
import { appUrl } from "../../../constants/url-constants";
import { DEPRICATED_BASE_URL } from "../../../constants/app-constants";

const ArrowLiCollection = ({
    items = [],
    heading
}) => {
    const { createLink } = useLink();
    return (
        <React.Fragment>
            <H4 text={heading} />
            <ul>
                {
                    items.map((data, key) => {
                        const baseURL = data.title === "CARS24 India" ? DEPRICATED_BASE_URL : appUrl();
                        const slug = data.title === "Blog" ? "/blog/" : data.url;
                        return (
                        <LiWithArrow
                            key={key}
                            title={data.title}
                            url={createLink(`${slug}`, baseURL)?.absoluteUrl || data.url}
                        />
                    );
})
                }

            </ul>
        </React.Fragment>
    );
};

ArrowLiCollection.propTypes = {
    items: PropTypes.array.isRequired,
    addAe: PropTypes.bool,
    heading: PropTypes.string
};
export default ArrowLiCollection;
